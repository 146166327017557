import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { AxiosResponse } from 'axios';
import useSWR, { mutate } from 'swr';

import { signInModalInstance } from 'components/candidate/SignInModal';
import { NotificationType, TouchpointNotifications } from 'components/candidate/Notifications/TouchpointNotifications.component';
import { joinMemberModalInstance } from 'components/candidate/JoinMemberModal';
import { useCommon } from 'hooks/useCommon';
import { useCandidate } from 'hooks/useCandidate';
import { StudentSocietiesResponse, StudentSocietyResponse, TStudentSociety } from 'lib/models/student-society';
import { useAuth } from 'lib/providers/AuthProvider';
import { API_ROUTES } from 'lib/api-routes';
import { apiInstance } from 'lib/utils/axios';
import { RoomAttributes, RoomResponse } from 'lib/models/candidate-messages';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useApp } from 'lib/contexts/save-in-app-context';
import { AcceptInvitationResponse } from 'lib/models/invitation';

export enum UserActionEnum {
  JOIN_COMMUNITY = 'join_society',
  DISJOIN_COMMUNITY = 'disjoin_society',
  MESSAGE_COMMUNITY = 'message_society',
}

type UpdateUserActionProps =
  | {
      userAction: UserActionEnum.JOIN_COMMUNITY | UserActionEnum.DISJOIN_COMMUNITY | UserActionEnum.MESSAGE_COMMUNITY;
      userActionId: string;
    }
  | { userAction: null };

export function useCommunityInternalHook({ community: staticPropsCommunity }: { community: TStudentSociety }) {
  const { replace, query, push } = useRouter();
  const { isInitialAuthLoading, isCandidate, candidate, updateUserApiResponse } = useAuth();
  const { updateCount } = useCandidate();
  const { student_society_follow_count, first_name, last_name } = candidate || { student_society_follow_count: 0, first_name: '', last_name: '' };
  const { setRoomData } = useApp();
  const { onJoinSociety, onDisjoinSociety } = useCommon();
  const touchpointNotificationInstance = TouchpointNotifications();
  const { user_action: queryUserAction, user_action_id = null, invitation_token } = query;
  const queryUserActionId = user_action_id as string;
  const communityId = staticPropsCommunity.id;
  const communityName = staticPropsCommunity.name;

  const [isFollowLoading, setIsFollowLoading] = useState(false);
  const [isMessageLoading, setIsMessageLoading] = useState(false);

  const shouldFetchCommunity = isCandidate;
  const communityPath = `${API_ROUTES.STUDENT_SOCIETIES}/${communityId}${shouldFetchCommunity ? '/' : ''}`;

  const communityFallbackData = useMemo(() => {
    return {
      data: { data: { id: communityId, type: 'student_society', attributes: { ...staticPropsCommunity } } },
    } as AxiosResponse<StudentSocietyResponse>;
  }, [staticPropsCommunity]);

  const { data: communityResponse, isLoading: isCommunityLoading } = useSWR<AxiosResponse<StudentSocietyResponse>>(
    communityPath,
    shouldFetchCommunity ? apiInstance.get : null,
    {
      fallbackData: communityFallbackData,
      revalidateOnFocus: false,
    }
  );

  const community = useMemo(() => {
    if (isCommunityLoading) return staticPropsCommunity;
    return communityResponse?.data.data.attributes || staticPropsCommunity;
  }, [isCommunityLoading, communityResponse, staticPropsCommunity]);

  const { subscribers_count, subscribers_require_approval, awaiting_approval, slug } = community;
  const isApprovalRequired = subscribers_require_approval && awaiting_approval;

  const updateCommunityFields = useCallback(
    (fields: Partial<TStudentSociety>) => {
      mutate(
        communityPath,
        () => {
          if (!communityResponse) return communityResponse;
          return {
            ...communityResponse,
            data: {
              ...communityResponse.data,
              data: {
                ...communityResponse.data.data,
                attributes: {
                  ...communityResponse.data.data.attributes,
                  ...fields,
                },
              },
            },
          };
        },
        { revalidate: false }
      );
    },
    [communityResponse]
  );

  const similarCommunitiesPathname = isInitialAuthLoading
    ? null
    : `${API_ROUTES.STUDENT_SOCIETIES}/${slug}${API_ROUTES.SIMILAR_STUDENT_SOCIETIES}${
        isCandidate ? '/' : ''
      }?sort_by=updated_at&sort_order=desc&with_details=true&page=1&per_page=10`;

  const { data: similarCommunitiesResponse, mutate: mutateSimilarCommunitiesResponse } = useSWR<AxiosResponse<StudentSocietiesResponse>>(
    similarCommunitiesPathname,
    apiInstance.get,
    {
      revalidateOnFocus: false,
    }
  );
  const similarCommunities = useMemo(() => {
    return parseArrayResponse(similarCommunitiesResponse?.data);
  }, [similarCommunitiesResponse]);

  const updateSimilarCompanyFields = useCallback(
    (fields: Partial<TStudentSociety>) => {
      mutateSimilarCommunitiesResponse(
        (oldSimilarCommunitiesResponse) => {
          if (!oldSimilarCommunitiesResponse) return oldSimilarCommunitiesResponse;

          return {
            ...oldSimilarCommunitiesResponse,
            data: {
              ...oldSimilarCommunitiesResponse.data,
              data: oldSimilarCommunitiesResponse.data.data.map((community) => {
                if (community.id === fields.id) {
                  return {
                    ...community,
                    attributes: {
                      ...community.attributes,
                      ...fields,
                    },
                  };
                }
                return community;
              }),
            },
          };
        },
        { revalidate: false }
      );
    },
    [mutateSimilarCommunitiesResponse]
  );

  const updateUserAction = useCallback(
    (props: UpdateUserActionProps) => {
      const { userAction } = props;
      if ('userActionId' in props) {
        replace({ query: { ...query, user_action: userAction, user_action_id: props.userActionId } }, undefined, { shallow: true });
      } else {
        if (userAction) {
          replace({ query: { ...query, user_action: userAction } }, undefined, { shallow: true });
        } else {
          const { user_action: _, user_action_id: __, ...restQuery } = query;
          replace({ query: restQuery }, undefined, { shallow: true });
        }
      }
    },
    [replace, query]
  );

  const toggleJoinCommunity = useCallback(async () => {
    switch (queryUserAction) {
      case UserActionEnum.JOIN_COMMUNITY: {
        const response = await onJoinSociety({ email: candidate?.email || '', student_society_id: queryUserActionId });
        const { member, approved } = response;

        let societyName;
        if (communityId === queryUserActionId) {
          const record =
            member && approved
              ? { subscribed: true, awaiting_approval: false, subscribers_count: subscribers_count + 1 }
              : { subscribed: false, awaiting_approval: true };
          updateCommunityFields({ ...record });
          societyName = communityName;
        } else {
          const record = member && approved ? { subscribed: true, awaiting_approval: false } : { subscribed: false, awaiting_approval: true };
          updateSimilarCompanyFields({ id: queryUserActionId, ...record });
          societyName = similarCommunities.find((item) => item.id === queryUserActionId)?.name;
        }
        touchpointNotificationInstance({
          notificationType: NotificationType.SOCIETY_JOIN,
          followCount: student_society_follow_count,
          name: societyName,
          approvalReq: !(member && approved),
        });
        updateUserAction({ userAction: null });
        updateCount({
          student_society_follow_count: student_society_follow_count! + 1,
        });
        break;
      }
      case UserActionEnum.DISJOIN_COMMUNITY: {
        await onDisjoinSociety({ id: queryUserActionId });
        updateUserAction({ userAction: null });

        if (communityId === queryUserActionId) {
          const record = { subscribed: false, awaiting_approval: false, subscribers_count: subscribers_count - 1 };
          updateCommunityFields({ ...record });
        } else {
          const record = { subscribed: false, awaiting_approval: false };
          updateSimilarCompanyFields({ id: queryUserActionId, ...record });
        }
        break;
      }
      default:
        break;
    }
  }, [
    communityId,
    queryUserActionId,
    subscribers_count,
    queryUserAction,
    candidate,
    communityName,
    isApprovalRequired,
    student_society_follow_count,
    updateUserAction,
    onJoinSociety,
    onDisjoinSociety,
    updateCommunityFields,
    updateCount,
    updateSimilarCompanyFields,
    touchpointNotificationInstance,
  ]);

  const handleToggleFollowingCommunity = useCallback(async () => {
    try {
      if (isCandidate) {
        const toggleCommunity = async () => {
          setIsFollowLoading(true);
          await toggleJoinCommunity();
        };
        if (queryUserAction === UserActionEnum.DISJOIN_COMMUNITY) {
          toggleCommunity();
        } else {
          if (first_name && last_name) {
            toggleCommunity();
          } else {
            let societyName, approvalRequired;
            if (communityId === queryUserActionId) {
              societyName = communityName;
              approvalRequired = subscribers_require_approval;
            } else {
              const similarSociety = similarCommunities.find((item) => item.id === queryUserActionId);
              societyName = similarSociety?.name || '';
              approvalRequired = similarSociety?.subscribers_require_approval;
            }

            const data = await joinMemberModalInstance({
              societyName: societyName,
              isPrivateSociety: approvalRequired,
            });
            if (data || !approvalRequired) {
              toggleCommunity();
            }
          }
        }
      } else {
        await signInModalInstance({ signInFromSociety: true, societyInfo: community });
      }
    } catch (error) {
      console.error(error);
      updateUserAction({ userAction: null });
    } finally {
      setIsFollowLoading(false);
    }
  }, [isCandidate, toggleJoinCommunity, updateUserAction, signInModalInstance]);

  const messageCommunity = useCallback(async () => {
    const body = {
      participants_attributes: [{ participable_id: communityId, participable_type: 'StudentSociety' }],
    };
    const { data } = await apiInstance.post<RoomResponse>(API_ROUTES.CANDIDATE_ROOM, body);
    const parsedResponse = parseResponse<RoomAttributes>(data);
    setRoomData(parsedResponse);
    updateUserAction({ userAction: null });
    push(PAGE_ROUTES.CANDIDATE_MESSAGES);
  }, [communityId, push, setRoomData]);

  const handleMessageCommunity = useCallback(async () => {
    try {
      if (isCandidate) {
        const sendMessage = async () => {
          setIsMessageLoading(true);
          await messageCommunity();
        };
        if (first_name && last_name) {
          sendMessage();
        } else {
          const data = await joinMemberModalInstance({
            societyName: communityName,
            messageModal: true,
          });
          if (data) {
            sendMessage();
          }
        }
      } else {
        await signInModalInstance();
      }
    } catch (error) {
      console.error(error);
      updateUserAction({ userAction: null });
    } finally {
      setIsMessageLoading(false);
    }
  }, [isCandidate, messageCommunity, updateUserAction, signInModalInstance]);

  const handleViewSociety = useCallback(async () => {
    try {
      const pathname = `${API_ROUTES.STUDENT_SOCIETIES}/${communityId}/viewed`;
      await apiInstance.post(pathname);
    } catch (error) {
      console.error(error);
    }
  }, [communityId]);

  const handleClickCustomLink = useCallback(async () => {
    try {
      const pathname = `${API_ROUTES.STUDENT_SOCIETIES}/${communityId}/clicked_links`;
      await apiInstance.post(pathname);
    } catch (error) {
      console.error(error);
    }
  }, [communityId]);

  useEffect(() => {
    if (isInitialAuthLoading) return;

    if (Object.values(UserActionEnum).includes(queryUserAction as UserActionEnum)) {
      switch (queryUserAction) {
        case UserActionEnum.JOIN_COMMUNITY:
        case UserActionEnum.DISJOIN_COMMUNITY:
          handleToggleFollowingCommunity();
          break;
        case UserActionEnum.MESSAGE_COMMUNITY:
          handleMessageCommunity();
          break;
        default:
          break;
      }
    }
  }, [isInitialAuthLoading, isCandidate, queryUserAction]);

  useEffect(() => {
    async function acceptInvitation() {
      if (!invitation_token) return;

      const body = { invitation: { token: invitation_token } };
      const { data } = await apiInstance.post<AcceptInvitationResponse>(API_ROUTES.ACCEPT_INVITATION, body);
      updateCommunityFields({ subscribed: true, awaiting_approval: false, subscribers_count: subscribers_count + 1 });
      const userApiResponse = parseResponse(data);
      updateUserApiResponse(userApiResponse.candidate);
    }

    try {
      acceptInvitation();
    } catch (error) {
      console.error(error);
    }
  }, [invitation_token]);

  const socialMediaLinks = useMemo(
    () =>
      [
        { icon: 'icon_web', url: community.website },
        { icon: 'icon_facebook', url: community.facebook_url },
        { icon: 'icon_x', url: community.twitter_url },
        { icon: 'icon_instagram', url: community.instagram_url },
        { icon: 'icon_su', url: community.student_union_url },
        { icon: 'icon_linkedin', url: community.linkedin_url },
      ]
        .filter(({ url }) => url)
        .sort((a, b) => {
          if (a.url && b.url) return 0;
          else if (a.url) return -1;
          else if (b.url) return 1;
          return 0;
        }),
    [community]
  );

  return {
    community,
    similarCommunities,
    isFollowLoading,
    isMessageLoading,
    socialMediaLinks,
    updateUserAction,
    handleViewSociety,
    handleClickCustomLink,
  };
}

export type UseCommunityReturnType = ReturnType<typeof useCommunityInternalHook>;
