import { TStudentSociety } from 'lib/models/student-society';

export function getCommunityMetaDescription(community: TStudentSociety) {
  const { short_description, description } = community;
  const pageDescription = short_description
    ? `${
        short_description.endsWith('.')
          ? short_description.trim()
          : `${short_description.trim()}.`
      } Latest events & updates on Huzzle!`
    : `Latest events & updates on Huzzle. ${description}`;
  const metaDescription =
    pageDescription.length <= 160
      ? pageDescription.trim()
      : `${pageDescription.substring(0, 157).trim()}...`;

  return metaDescription;
}
