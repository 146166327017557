import React, { useContext, createContext } from 'react';
import { noop } from 'lodash';

import {
  UseCommunityReturnType,
  useCommunityInternalHook,
} from 'hooks/useCommunity/useCommunityInternal';
import { TStudentSociety } from 'lib/models/student-society';

const initialContext: UseCommunityReturnType = {
  community: {} as TStudentSociety,
  similarCommunities: [],
  isFollowLoading: false,
  isMessageLoading: false,
  socialMediaLinks: [],
  updateUserAction: noop,
  handleViewSociety: () => Promise.resolve(),
  handleClickCustomLink: () => Promise.resolve(),
};

const CommunityContext = createContext<UseCommunityReturnType>(initialContext);

export const useCommunity = () => useContext(CommunityContext);

export const CommunityProvider: React.FC<{
  community: TStudentSociety;
}> = ({ children, community }) => {
  const value = useCommunityInternalHook({
    community,
  });

  return (
    <CommunityContext.Provider value={value}>
      {children}
    </CommunityContext.Provider>
  );
};
