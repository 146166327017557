import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { ButtonV3, Chip, Stat } from 'components/ComponentV2';
import { ContentSection } from 'components/organization-page';
import { CommunityLinks } from 'components/community-page';
import Avatar from 'components/Avatar';
import Link from 'components/Link';
import { useResponsive } from 'hooks/useResponsive';
import { useCommunity } from 'lib/providers/CommunityProvider';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import { PAGE_ROUTES } from 'lib/page-routes';
import { CommitteeAttributes } from 'lib/models/committee';

import styles from './CommunitySidebar.module.scss';

const CommunityPartners = ({ className }: { className?: string }) => {
  const [tc] = useTranslation('common');
  const screens = useResponsive();
  const {
    community: { sponsorships },
  } = useCommunity();
  const sponsoredCompanies = parseArrayResponse(sponsorships).map(({ company }) => parseResponse(company));
  if (sponsoredCompanies.length === 0) {
    return null;
  }

  return (
    <ContentSection title={tc('partners')} className={cx(styles['partners-container'], className)}>
      <div className={styles.partners}>
        {sponsoredCompanies.map((company) => (
          <div key={company.id} className={styles.partner}>
            <Avatar icon={company.icon_url} name={company.name} size={screens.sm ? 'medium' : 'xsmall'} />
            {screens.sm ? (
              <p className={styles.name}>{company.name}</p>
            ) : (
              <Link href={`${PAGE_ROUTES.COMPANIES}/${company.slug}`} target="_blank" className={styles.link}>
                {company.name}
              </Link>
            )}
          </div>
        ))}
      </div>
    </ContentSection>
  );
};

const ManagerCard = ({ manager }: { manager: CommitteeAttributes }) => {
  const screens = useResponsive();
  return (
    <div className={styles['team-member']}>
      <Avatar icon={manager.avatar_url} name={manager.name} size={screens.sm ? '40' : '36'} className={styles.avatar} />
      <div className={styles['info']}>
        <h4 className={styles['name']}>{manager.name}</h4>
        <p className={styles['role']}>{manager.role}</p>
      </div>
    </div>
  );
};

export const CommunitySidebar = ({ className }: { className?: string }) => {
  const [t] = useTranslation('student-society');
  const { community } = useCommunity();
  const screens = useResponsive();
  const [allManagersExpanded, setAllManagersExpanded] = useState(false);
  const {
    stats: statsResponse,
    managers: managersResponse,
    offerings: offeringsResponse,
    topics: topicsResponse,
    student_societies_highlights: highlightsResponse,
  } = community;
  const stats = parseArrayResponse(statsResponse);
  const managers = parseArrayResponse(managersResponse);
  const offerings = parseArrayResponse(offeringsResponse);
  const topics = parseArrayResponse(topicsResponse);
  const highlights = parseArrayResponse(highlightsResponse);
  const formatter = new Intl.ListFormat('en-GB', {
    style: 'short',
    type: 'conjunction',
  });
  const offeringsString = formatter.format(offerings.map(({ name }) => name));

  const topicsContent = useMemo(
    () =>
      topics.map((topic, index) => (
        <Fragment key={topic.id}>
          <a href={`${PAGE_ROUTES.SOCIETIES_SEARCH}&topic_ids=${topic.id}`} className={styles['topic-link']} target="_blank" rel="noreferrer">
            {topic.name}
          </a>
          {index < topics.length - 1 && <span className={styles['topic-separator']}>,</span>}
        </Fragment>
      )),
    [topics]
  );

  return (
    <article className={cx(styles.sidebar, className)}>
      {!screens.sm && <CommunityLinks className={styles['community-links']} />}
      <section className={styles.container}>
        {stats.length > 0 && (
          <div className={styles.stats}>
            {stats.map(({ id, title, value }) => (
              <Stat key={id} value={value} label={title} />
            ))}
          </div>
        )}
        {screens.sm && <CommunityPartners className={styles['partners-end']} />}
        {managers.length > 0 && (
          <ContentSection title={t('sidebar.team')}>
            <div
              className={cx(styles['team-members'], {
                [styles.expanded]: allManagersExpanded,
              })}
            >
              {managers.map((manager) => (
                <ManagerCard key={manager.id} manager={manager} />
              ))}
              {!allManagersExpanded && managers.length > 5 && (
                <div className={styles['show-more-action']}>
                  <ButtonV3
                    color="secondary"
                    borderless={!screens.sm}
                    size={screens.sm ? 'medium' : 'small'}
                    onClick={() => setAllManagersExpanded(true)}
                  >
                    {t('sidebar.show-more')}
                  </ButtonV3>
                </div>
              )}
            </div>
          </ContentSection>
        )}
        {highlights.length > 0 && (
          <ContentSection title={t('sidebar.highlights')}>
            <div className={styles.highlights}>
              {highlights.map(({ id, name }) => (
                <Chip wrap key={id}>
                  {name}
                </Chip>
              ))}
            </div>
          </ContentSection>
        )}
        {offeringsString && (
          <ContentSection title={t('sidebar.offerings')}>
            <p className={styles.offerings}>{offeringsString}</p>
          </ContentSection>
        )}
        {topics.length > 0 && (
          <ContentSection title={t('sidebar.topics')}>
            <div className={styles.topics}>{topicsContent}</div>
          </ContentSection>
        )}
      </section>
      {!screens.sm && <CommunityPartners className={styles['partners-end']} />}
    </article>
  );
};

CommunitySidebar.displayName = 'CommunitySidebar';
