import React from 'react';
import { useForm } from 'react-hook-form';
import { InstanceProps, create } from 'react-modal-promise';
import { useTranslation } from 'next-i18next';

import { Icon, IconButton } from 'components';
import { ButtonV3 } from 'components/ComponentV2';
import Modal from 'components/Modal';
import { Form as FormComponent, Input } from 'components/form';
import { Row } from 'components/layout';
import { useResponsive } from 'hooks/useResponsive';
import { FLAG_STATUS_CODES, useCandidate } from 'hooks/useCandidate';
import { useAuth } from 'lib/providers/AuthProvider';

import styles from './JoinMemberModal.module.scss';

interface ModalInstanceProps {
  societyName: string;
  isPrivateSociety?: boolean;
  messageModal?: boolean;
}
const ModalInstance: React.FC<
  ModalInstanceProps & InstanceProps<FLAG_STATUS_CODES>
> = ({
  isOpen,
  onResolve,
  onReject,
  societyName,
  isPrivateSociety = false,
  messageModal = false,
}) => {
  const screens = useResponsive();
  const [t] = useTranslation('common');
  const { updateCandidateInfo } = useCandidate();
  const { candidate } = useAuth();
  const { first_name: firstName, last_name: lastName } = candidate || {
    first_name: '',
    last_name: '',
  };

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm();

  const onSubmitForm = async (data: {
    first_name: string;
    last_name: string;
  }) => {
    const { first_name, last_name } = data;
    const response = await updateCandidateInfo({
      partialCandidateAttributes: {
        first_name,
        last_name,
      },
    });
    if (response) {
      onResolve(FLAG_STATUS_CODES.NAME_ADDED);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const isJoinButtonDisabled = !!(errors.first_name || errors.last_name);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onReject}
      contentClassName={styles.container}
    >
      <div className={styles.top}>
        <p className={styles.title}>
          {messageModal
            ? t('join-member-modal.message-title', { societyName })
            : t('join-member-modal.join-title', { societyName })}
        </p>
        <IconButton
          size="small"
          iconName="close"
          className={styles['cancel-icon']}
          onClick={() => onReject()}
        />
      </div>
      <FormComponent
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitForm)}
        onKeyDown={handleKeyDown}
      >
        <div className={styles.inputWrapper}>
          <p className={styles.desc}>
            {messageModal
              ? t('join-member-modal.message-desc', { societyName })
              : t('join-member-modal.join-desc', { societyName })}
          </p>

          {!firstName && (
            <div>
              <Input
                autoFocus
                type="first_name"
                label={t('join-member-modal.first-name.label')}
                placeholder={t('join-member-modal.first-name.placeholder')}
                {...register('first_name', {
                  required: isPrivateSociety || messageModal ? true : false,
                })}
                isValid={!errors.first_name}
              />
              {errors.first_name && (
                <Row
                  align="center"
                  columnGap={2}
                  className={styles['error-message']}
                >
                  <Icon
                    iconName="icon_info"
                    size="auto"
                    className={styles['error-message-icon']}
                  />
                  <p>{t('join-member-modal.error-message')}</p>
                </Row>
              )}
            </div>
          )}
          {!lastName && (
            <div>
              <Input
                type="last_name"
                label={t('join-member-modal.last-name.label')}
                placeholder={t('join-member-modal.last-name.placeholder')}
                {...register('last_name', {
                  required: isPrivateSociety || messageModal ? true : false,
                })}
                isValid={!errors.last_name}
              />
              {errors.last_name && (
                <Row
                  align="center"
                  columnGap={2}
                  className={styles['error-message']}
                >
                  <Icon
                    iconName="icon_info"
                    size="auto"
                    className={styles['error-message-icon']}
                  />
                  <p>{t('join-member-modal.error-message')}</p>
                </Row>
              )}
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <ButtonV3
            size={screens.sm ? 'large' : 'medium'}
            type="submit"
            startIcon={messageModal ? undefined : 'icon_plus'}
            isLoading={isSubmitting}
            disabled={isJoinButtonDisabled}
            isFullWidth={screens.sm ? true : false}
          >
            {messageModal
              ? t('join-member-modal.continue')
              : t('join-member-modal.join')}
          </ButtonV3>
        </div>
      </FormComponent>
    </Modal>
  );
};

export const joinMemberModalInstance = create(ModalInstance);
