import { useCallback, useEffect, useMemo } from 'react';

import { OrganizationPageLayout, SimilarOrganizations } from 'components/organization-page';
import { CommunityActions, CommunitySidebar, CommunitySectionGroup } from 'components/community-page';
import { NewPostModal, PostModal } from 'components/discussions';
import { useResponsive } from 'hooks/useResponsive';
import { UserActionEnum } from 'hooks/useCommunity/useCommunityInternal';
import { useCommunity } from 'lib/providers/CommunityProvider';
import { parseResponse } from 'lib/utils/parser';
import { DiscussionsProvider } from 'lib/providers/DiscussionsProvider';
import { DiscussionProvider } from 'lib/providers/DiscussionProvider';
import { TDocumentPost, TMediaPost, TPost } from 'lib/models/discussion';
import { OrganizationTypeEnum } from 'lib/models/organization';
import { getUserAvatarFields } from 'utils/user';

export const Community = ({
  initialPosts,
  initialMediaAttachments,
  initialDocumentAttachments,
}: {
  initialPosts: Array<TPost>;
  initialMediaAttachments: TMediaPost['media'];
  initialDocumentAttachments: TDocumentPost['documents'];
}) => {
  const { community, handleViewSociety, similarCommunities, updateUserAction } = useCommunity();
  const screens = useResponsive();
  const { name, banner_url, university, short_description, subscribed } = community;
  const universityName = parseResponse(university)?.name;
  const communityAvatar = useMemo(() => getUserAvatarFields({ society: community }), [community]);

  useEffect(() => {
    handleViewSociety();
  }, [handleViewSociety]);

  const onJoin = useCallback(({ organizationId }: { organizationId: string }) => {
    updateUserAction({ userAction: UserActionEnum.JOIN_COMMUNITY, userActionId: organizationId });
  }, []);

  const onDisjoin = useCallback(({ organizationId }: { organizationId: string }) => {
    updateUserAction({ userAction: UserActionEnum.DISJOIN_COMMUNITY, userActionId: organizationId });
  }, []);

  return (
    <DiscussionsProvider
      studentSociety={community}
      isCandidatePage={true}
      isSocietyMember={!!subscribed}
      initialPosts={initialPosts}
      initialMediaAttachments={initialMediaAttachments}
      initialDocumentAttachments={initialDocumentAttachments}
    >
      <DiscussionProvider>
        <OrganizationPageLayout
          organizationName={name}
          shortDescription={short_description}
          addressLineContent={universityName}
          bannerURL={banner_url}
          organizationAvatar={communityAvatar}
          organizationType={OrganizationTypeEnum.COMMUNITY}
        >
          <CommunityActions buttonSize={screens.sm ? 'medium' : 'large'} />
          <CommunitySectionGroup />
          <SimilarOrganizations
            organizationType={OrganizationTypeEnum.COMMUNITY}
            organizations={similarCommunities}
            onFollow={onJoin}
            onUnfollow={onDisjoin}
          ></SimilarOrganizations>
          {!screens.sm && <CommunitySidebar />}
        </OrganizationPageLayout>
        <PostModal />
        <NewPostModal />
      </DiscussionProvider>
    </DiscussionsProvider>
  );
};

Community.displayName = 'Community';
