import React, { ReactElement, useMemo } from 'react';
import SlideShow from 'components/SlideShow';
import OpportunityCard from 'components/OpportunityCard';
import { useResponsive } from 'hooks/useResponsive';
import { TouchpointType } from 'lib/models/touchpoint';
import { FlagshipEventAttributes } from 'lib/models/flagship-event';
import styles from './events.module.scss';

const FlagshipEventsSlider = ({
  flagshipEvents,
  flagshipEventsSlideCount,
  created_by,
}: {
  flagshipEvents: FlagshipEventAttributes[];
  flagshipEventsSlideCount: number;
  created_by: string;
}): ReactElement => {
  const screens = useResponsive();

  const flagshipEventsCard = useMemo(
    () =>
      flagshipEvents.map(
        ({ id, title, url, icon_url }) =>
          url && (
            <div key={id} className={styles.flagship}>
              <OpportunityCard
                key={id}
                banner={icon_url}
                title={title}
                start_date={null}
                touchpointable_type={TouchpointType.Event}
                created_at={null}
                url={url}
                created_by={''}
                className={styles.flagshipCard}
                bannerAltText={`Photo of Flagship Event of ${created_by} called ${title}`}
              />
            </div>
          )
      ),
    [flagshipEvents]
  );

  return (
    <>
      {screens.sm ? (
        <div className={styles.flagshipMobile}>{flagshipEventsCard}</div>
      ) : (
        <SlideShow
          slidesPerRow={flagshipEventsSlideCount}
          gutter={20}
          partialSlide={0.25}
        >
          {flagshipEventsCard}
        </SlideShow>
      )}
    </>
  );
};

export default FlagshipEventsSlider;
