import React, { memo } from 'react';
import cn from 'classnames';
import format from 'date-fns/format';

import { Image, Link } from 'components';
import { Row } from 'components/layout';
import DatePosted from 'components/DatePosted';
import { TouchpointType } from 'lib/models/touchpoint';
import { getCardLabel } from 'utils/format';
import styles from './OpportunityCard.module.scss';

export interface OpportunityCardProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** banner url of the opportunity */
  banner: string;
  /** title of the opportunity */
  title: string;
  /** type of the opportunity */
  touchpointable_type: TouchpointType;
  /** start date of the opportunity */
  start_date: string | null;
  /** url for the opportunity */
  url: string;
  created_at: string | null;
  created_by: string;
  bannerAltText: string;
}

export const OpportunityCard: React.FC<OpportunityCardProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    banner,
    title,
    start_date,
    touchpointable_type,
    url,
    created_at,
    created_by,
    bannerAltText,
  }: OpportunityCardProps) => {
    const contentClassNames = cn(styles.OpportunityCard, className);

    const startDateTimeValue = start_date
      ? format(new Date(start_date), 'MMM d, h:mma')
      : null;

    return (
      <Link href={url} underlineOnHover={false} className={styles.cardLink}>
        <section
          className={contentClassNames}
          style={style}
          data-testid="OpportunityCard"
        >
          {banner ? (
            <Image src={banner} className={styles.banner} alt={bannerAltText} />
          ) : (
            <div className={styles.dummyBanner}></div>
          )}
          <main className={styles.section}>
            <Row align="center" className={styles.header}>
              <p
                className={cn(styles.type, {
                  [styles.event]: touchpointable_type === TouchpointType.Event,
                  [styles.job]: touchpointable_type === TouchpointType.Job,
                  [styles.internship]:
                    touchpointable_type === TouchpointType.Internship,
                })}
              >
                {getCardLabel(touchpointable_type)}
              </p>
              {start_date && touchpointable_type === TouchpointType.Event && (
                <>
                  <p className={styles.dot}>•</p>
                  <p className={styles.startdate}>{startDateTimeValue}</p>
                </>
              )}
            </Row>
            <p className={styles.title}>{title}</p>
            <Row align="center" className={styles.info}>
              <p className={styles.infoTitle}>{created_by}</p>
              {created_at && (
                <Row align="center" className={styles.infoDate}>
                  <div className={styles.dot}>•</div>
                  <DatePosted created_at={created_at} />
                </Row>
              )}
            </Row>
          </main>
        </section>
      </Link>
    );
  }
);

OpportunityCard.displayName = 'OpportunityCard';
