import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { ContentSection } from 'components/organization-page';
import { ButtonV3 } from 'components/ComponentV2';
import { IconName } from 'components/Icon';
import Link from 'components/Link';
import IconButton from 'components/IconButton';
import { useResponsive } from 'hooks/useResponsive';
import { parseArrayResponse } from 'lib/utils/parser';
import { useCommunity } from 'lib/providers/CommunityProvider';

import styles from './CommunityLinks.module.scss';

export const CommunityLinks = ({
  className,
  contentClassName,
  hideTitle,
}: {
  className?: string;
  contentClassName?: string;
  hideTitle?: boolean;
}) => {
  const [t] = useTranslation('student-society');
  const { community, socialMediaLinks, handleClickCustomLink } = useCommunity();
  const { custom_links } = community;
  const screens = useResponsive();
  const customLinks = parseArrayResponse(custom_links);

  return (
    <div className={cx(styles['community-links'], className)}>
      {(customLinks.length > 0 || socialMediaLinks.length > 0) && (
        <ContentSection title={t('sidebar.links')} className={cx(styles['links-container'], contentClassName)} hideTitle={hideTitle}>
          <div className={styles.links}>
            {customLinks.length > 0 && (
              <div className={styles['custom-links']}>
                {customLinks.map(({ id, title, link }) =>
                  screens.sm ? (
                    <Link key={id} href={link} target="_blank" onAnchorClick={handleClickCustomLink}>
                      <ButtonV3 color="secondary" isFullWidth>
                        {title}
                      </ButtonV3>
                    </Link>
                  ) : (
                    <Link key={id} href={link} target="_blank" className={styles.link} onAnchorClick={handleClickCustomLink}>
                      {title}
                    </Link>
                  )
                )}
              </div>
            )}
            {socialMediaLinks.length > 0 && (
              <div className={styles.socials}>
                {socialMediaLinks.map(({ icon, url }) => (
                  <a href={url} key={icon} rel="noreferrer" target="_blank">
                    <IconButton iconName={icon as IconName} className={styles.icon} size="large" />
                  </a>
                ))}
              </div>
            )}
          </div>
        </ContentSection>
      )}
    </div>
  );
};
