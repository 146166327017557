import { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommunityActions, CommunityLinks, CommunitySidebar } from 'components/community-page';
import { ContentSection, OrganizationEvents, OrganizationSectionGroup } from 'components/organization-page';
import { DiscussionsDocs, DiscussionsMedia, DiscussionsPosts, SubscriberRequireApprovalContent } from 'components/discussions';
import { FreeText } from 'components/ComponentV2';
import FlagshipEventsSlider from 'components/Society/FlagshipEventsSlider';
import { UserActionEnum } from 'hooks/useCommunity/useCommunityInternal';
import { useResponsive } from 'hooks/useResponsive';
import { useCommunity } from 'lib/providers/CommunityProvider';
import { FlagshipEventAttributes } from 'lib/models/flagship-event';
import { parseArrayResponse } from 'lib/utils/parser';
import { useOrganizationEvents } from 'lib/providers/OrganizationEventsProvider';
import { useDiscussions } from 'lib/providers/DiscussionsProvider';
import { TPostParticipant } from 'lib/models/student-society';

enum TabPanelIds {
  EVENTS = 'events',
  LINKS = 'links',
  DISCUSSIONS = 'discussions',
  ABOUT = 'about',
  MEDIA = 'media',
  DOCS = 'docs',
}

import { OrganizationTypeEnum } from 'lib/models/organization';
import styles from './CommunitySectionGroup.module.scss';

const SubscriberRequireApprovalContentWrap: React.FC<{
  children: ReactElement;
  messageKey: string;
}> = ({ children, messageKey }) => {
  const { community, updateUserAction } = useCommunity();
  const { id: communityId, subscribers_require_approval, awaiting_approval, subscribed } = community;
  const [tDiscussions] = useTranslation('discussions');
  const hideTabContents = subscribers_require_approval && (awaiting_approval || !subscribed);

  const onSubscribe = useCallback(() => {
    updateUserAction({
      userAction: UserActionEnum.JOIN_COMMUNITY,
      userActionId: communityId,
    });
  }, [communityId]);

  return hideTabContents ? (
    <SubscriberRequireApprovalContent
      message={tDiscussions(messageKey, {
        society_name: community.name,
      })}
      onSubscribe={onSubscribe}
      isAwaitingApproval={awaiting_approval}
    />
  ) : (
    children
  );
};

export const CommunitySectionGroup = () => {
  const [t] = useTranslation('student-society');
  const screens = useResponsive();
  const { community } = useCommunity();
  const { societyDiscussionsSettings, posts, mediaAttachments, documentAttachments } = useDiscussions();
  const { publishedEventsTotalCount, showEventsTab } = useOrganizationEvents();
  const {
    description,
    name: communityName,
    flagship_events: flagshipEventsResponse,
    custom_links,
    stats: statsResponse,
    managers: managersResponse,
    offerings: offeringsResponse,
    topics: topicsResponse,
    student_societies_highlights: highlightsResponse,
    sponsorships: sponsorshipsResponse,
    subscribed,
    subscribers_require_approval,
    awaiting_approval,
  } = community;
  const askToSubscribe = subscribers_require_approval && (awaiting_approval || !subscribed);
  const customLinks = useMemo(() => parseArrayResponse(custom_links), [custom_links]);
  const stats = parseArrayResponse(statsResponse);
  const managers = parseArrayResponse(managersResponse);
  const offerings = parseArrayResponse(offeringsResponse);
  const topics = parseArrayResponse(topicsResponse);
  const highlights = parseArrayResponse(highlightsResponse);
  const sponsorships = parseArrayResponse(sponsorshipsResponse);

  const flagshipEvents = useMemo(() => {
    return parseArrayResponse<FlagshipEventAttributes>(flagshipEventsResponse);
  }, [flagshipEventsResponse]);

  const showSidebarContent =
    screens.sm && (stats.length || managers.length || offerings.length || topics.length || highlights.length || sponsorships.length);
  const showAboutTab = showSidebarContent || description || flagshipEvents.length > 0;
  const showLinksTab = screens.sm && customLinks.length > 0;
  const showDiscussionsTab =
    askToSubscribe ||
    (!askToSubscribe &&
      (posts.length > 0 ||
        societyDiscussionsSettings.who_can_post === TPostParticipant.ANYONE ||
        (societyDiscussionsSettings.who_can_post === TPostParticipant.MEMBERS_ONLY && subscribed) ||
        (societyDiscussionsSettings.who_can_reply_to_posts === TPostParticipant.ANYONE && posts.length > 0) ||
        (societyDiscussionsSettings.who_can_reply_to_posts === TPostParticipant.MEMBERS_ONLY && posts.length > 0 && subscribed)));
  const showMediaTab = !askToSubscribe && showDiscussionsTab && mediaAttachments.length > 0;
  const showDocsTab = !askToSubscribe && showDiscussionsTab && documentAttachments.length > 0;

  const tabList = useMemo(() => {
    return [
      {
        id: TabPanelIds.EVENTS,
        hash: `#${TabPanelIds.EVENTS}`,
        title: t('navigation.events'),
        badgeCount: publishedEventsTotalCount,
        hidden: !showEventsTab,
      },
      {
        id: TabPanelIds.LINKS,
        hash: `#${TabPanelIds.LINKS}`,
        title: t('navigation.links'),
        hidden: !showLinksTab,
      },
      {
        id: TabPanelIds.DISCUSSIONS,
        hash: `#${TabPanelIds.DISCUSSIONS}`,
        title: t('navigation.discussions'),
        hidden: !showDiscussionsTab,
      },
      {
        id: TabPanelIds.ABOUT,
        hash: `#${TabPanelIds.ABOUT}`,
        title: t('navigation.about'),
        hidden: !showAboutTab,
      },
      {
        id: TabPanelIds.MEDIA,
        hash: `#${TabPanelIds.MEDIA}`,
        title: t('navigation.media'),
        hidden: !showMediaTab,
      },
      {
        id: TabPanelIds.DOCS,
        hash: `#${TabPanelIds.DOCS}`,
        title: t('navigation.docs'),
        hidden: !showDocsTab,
      },
    ];
  }, [showEventsTab, showLinksTab, showDiscussionsTab, showAboutTab, showMediaTab, showDocsTab, publishedEventsTotalCount]);

  return (
    <OrganizationSectionGroup
      tabList={tabList}
      organizationActions={<CommunityActions buttonSize="small" isInsideStickyNavigation />}
      organizationType={OrganizationTypeEnum.COMMUNITY}
    >
      {showEventsTab && <OrganizationEvents />}
      {showLinksTab && <CommunityLinks contentClassName={styles['community-links-content']} hideTitle />}
      {showDiscussionsTab && (
        <SubscriberRequireApprovalContentWrap messageKey="subscriber-require-approval.discussions">
          <DiscussionsPosts />
        </SubscriberRequireApprovalContentWrap>
      )}
      {showAboutTab && (
        <>
          <FreeText content={description}></FreeText>
          <CommunitySidebar className={styles.sidebar} />
          {flagshipEvents.length > 0 && (
            <ContentSection
              title={t('navigation.flagship-events')}
              className={styles['flagship-events']}
              titleClassName={styles['flagship-events-title']}
            >
              <FlagshipEventsSlider flagshipEventsSlideCount={screens.sm ? 1 : 2} flagshipEvents={flagshipEvents} created_by={communityName} />
            </ContentSection>
          )}
        </>
      )}
      {showDiscussionsTab && (
        <SubscriberRequireApprovalContentWrap messageKey="subscriber-require-approval.media">
          <DiscussionsMedia mediaSize="small" />
        </SubscriberRequireApprovalContentWrap>
      )}
      {showDiscussionsTab && (
        <SubscriberRequireApprovalContentWrap messageKey="subscriber-require-approval.docs">
          <DiscussionsDocs docSize="small" />
        </SubscriberRequireApprovalContentWrap>
      )}
    </OrganizationSectionGroup>
  );
};

CommunitySectionGroup.displayName = 'CommunitySectionGroup';
