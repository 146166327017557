import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { IconName, Tooltip, IconButton, Link } from 'components';
import { ButtonV3 } from 'components/ComponentV2';
import { ButtonV3Props } from 'components/ComponentV2/ButtonV3';
import ProfilePics from 'components/candidate/ProfilePics';
import { UserActionEnum } from 'hooks/useCommunity/useCommunityInternal';
import { useResponsive } from 'hooks/useResponsive';
import { useNotification } from 'hooks/useNotification';
import { useCommunity } from 'lib/providers/CommunityProvider';
import { parseArrayResponse } from 'lib/utils/parser';
import { PAGE_ROUTES } from 'lib/page-routes';
import { formatToK } from 'utils/format';

import styles from './CommunityActions.module.scss';

export const CommunityActions = ({
  className,
  buttonSize = 'large',
  isInsideStickyNavigation = false,
}: {
  className?: string;
  buttonSize?: ButtonV3Props['size'];
  isInsideStickyNavigation?: boolean;
}) => {
  const [t] = useTranslation('student-society');
  const screens = useResponsive();
  const notificationInstance = useNotification();
  const { community, updateUserAction, isFollowLoading, isMessageLoading, socialMediaLinks } = useCommunity();
  const {
    id: communityId,
    is_verified,
    public_url,
    subscribed,
    subscribers_require_approval,
    awaiting_approval,
    custom_links,
    subscribed_avatar_urls,
    subscribers_count,
    banner_url,
  } = community;
  const customLinks = useMemo(() => parseArrayResponse(custom_links), [custom_links]);
  const awaitingApproval = subscribers_require_approval && awaiting_approval;
  const showSocialMediaLinks = customLinks.length === 0 && socialMediaLinks.length > 0;

  const onCopyURL = async () => {
    try {
      await navigator.clipboard.writeText(public_url);
      notificationInstance.information({
        title: t('actions.invitation-link-copied'),
        message: '',
        showClose: true,
        variant: 'small',
        borderRounded: false,
        icon: 'icon_check',
      });
    } catch (error) {
      console.error('Failed to copy URL to clipboard', error);
    }
  };

  return (
    <div className={styles.container}>
      {showSocialMediaLinks && screens.sm && (
        <div className={styles.socials}>
          {socialMediaLinks.map(({ icon, url }) => (
            <a href={url} key={icon} rel="noreferrer" target="_blank">
              <IconButton iconName={icon as IconName} className={styles.icon} size="large" />
            </a>
          ))}
        </div>
      )}
      {is_verified ? (
        <div className={cx(styles.actions, className, { [styles['has-banner']]: banner_url })}>
          {subscribed || awaitingApproval ? (
            <ButtonV3
              color="primary"
              size={buttonSize}
              isLoading={isFollowLoading}
              startIcon={awaitingApproval ? 'icon_check' : 'icon_check-double'}
              onClick={() =>
                updateUserAction({
                  userAction: UserActionEnum.DISJOIN_COMMUNITY,
                  userActionId: communityId,
                })
              }
            >
              {awaitingApproval ? t('actions.request-sent') : t('actions.member')}
            </ButtonV3>
          ) : (
            <ButtonV3
              color="primary"
              startIcon="icon_plus"
              size={buttonSize}
              isLoading={isFollowLoading}
              onClick={() =>
                updateUserAction({
                  userAction: UserActionEnum.JOIN_COMMUNITY,
                  userActionId: communityId,
                })
              }
            >
              {t('actions.join')}
            </ButtonV3>
          )}
          {screens.sm ? (
            <IconButton
              iconName="icon_send-message"
              size="medium"
              className={styles['message-icon']}
              onClick={() =>
                updateUserAction({
                  userAction: UserActionEnum.MESSAGE_COMMUNITY,
                  userActionId: communityId,
                })
              }
            />
          ) : (
            <ButtonV3
              color="secondary"
              startIcon="icon_send-message"
              size={buttonSize}
              isLoading={isMessageLoading}
              onClick={() =>
                updateUserAction({
                  userAction: UserActionEnum.MESSAGE_COMMUNITY,
                  userActionId: communityId,
                })
              }
            >
              {t('actions.message')}
            </ButtonV3>
          )}
          {!isInsideStickyNavigation && (
            <div className={styles['members']}>
              <ProfilePics maxPicsToShow={subscribers_count < 3 ? subscribers_count : 2} urls={subscribed_avatar_urls} className={styles.pics} />
              {subscribers_count > 0 && (
                <p className={styles['members-count']}>
                  {t('actions.members-count', { count: subscribers_count, countStr: formatToK(subscribers_count) })}
                </p>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className={cx(styles.actions, className, { [styles['has-banner']]: banner_url })}>
          <Tooltip
            content={t('actions.invite-tooltip')}
            position={isInsideStickyNavigation ? 'bottom' : 'top'}
            color="dark"
            isV2Design
            hasWhitespaceNoWrap={false}
            xAlign={screens.sm ? 'left' : 'center'}
            disabled={screens.sm}
          >
            <ButtonV3 color="primary" startIcon="icon_mail" size={buttonSize} onClick={onCopyURL}>
              {t('actions.invite-to-join')}
            </ButtonV3>
          </Tooltip>
          <Link href={PAGE_ROUTES.MANAGER_SIGN_UP} underlineOnHover={false}>
            <ButtonV3 color="secondary" size={buttonSize}>
              {t('actions.claim-profile')}
            </ButtonV3>
          </Link>
        </div>
      )}
    </div>
  );
};

CommunityActions.displayName = 'CommunityActions';
